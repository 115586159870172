@font-face{
  font-family: einer-grotesk-light;
  src: url(/assets/fonts/einer-grotesk/einer-grotesk-light.woff);
}

@font-face{
  font-family: einer-grotesk-regular;
  src: url(/assets/fonts/einer-grotesk/einer-grotesk-regular.woff);
}

@font-face{
  font-family: einer-grotesk-heavy;
  src: url(/assets/fonts/einer-grotesk/einer-grotesk-heavy.woff);
}
