@import './styling/reset';
@import './styling/fonts';
@import './styling/grid';
@import './styling/table';

p {
  font-family: einer-grotesk-light, sans-serif;
  font-size: 16px;
  line-height: 20px;
}

input {
  font-family: einer-grotesk-light, sans-serif;
  font-size: 16px;
}

h2 {
  font-family: einer-grotesk-heavy, sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 24px;
  color: #39414a;
  padding-bottom: 3px;
  border-bottom: 1px solid #39414a;
  position: relative;
  border-bottom: 0;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    top: calc(100% - 1px);
    left: 0;
    right: 0;
    background: linear-gradient(to left, #F57F20 0%, #E6584A 30%, #B14A62 60%,#58487D 100%);
  }
}

hr {
  border: 0;
  height: 1px;
  position: relative;
  width: 80%;
  margin: 8px auto;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    top: calc(100% - 1px);
    left: 0;
    right: 0;
    background: linear-gradient(to left, #F57F2000 0%, #E6584AFF 30%, #B14A62FF 60%,#58487D00 100%);
  }
}

h3 {
  font-family: einer-grotesk-light, sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 20px;
  color: #39414a;

  &.content-header {
    font-size: 14px;
    width: calc(100% - 24px);
    position: relative;
    left: 4px;
    margin-top: 8px;
    
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      top: calc(100% - 1px);
      left: 0;
      right: 0;
      background: linear-gradient(to left, #F57F20 0%, #E6584A 30%, #B14A62 60%,#58487D 100%);
    }
  }
}

section {
  margin-top: 16px;
  font-size: 16px;

  &.section--xl {
    margin-top: 24px;
  }

  &.section--s {
    margin-top: 8px;
  }
}

.graph-circle {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #F75700;
}

.graph-square {
  width: 8px;
  height: 8px;
  background-color: #F7175E;
}

.graph-triangle {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  position: relative;
  right: 2px;
  top: -1px;
  border-bottom: 8px solid #117a16;
}

ul li {
  padding-left: 16px;
  position: relative;
  font-family: einer-grotesk-light, serif;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    width: 3px;
    height: 3px;
    border-radius: 100%;
    background-color: black;
    top: 50%;
  }
}

input::placeholder {
  font-style: italic;
}
