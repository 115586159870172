.table {
  display: flex;
  flex-flow: column nowrap;
  font-size: 1rem;
  line-height: 19px;
  flex: 1 1 auto;
}

.th {
  display: none;
  font-weight: 700;
  background-color: #f2f2f2;
}

.th > .td {
  white-space: normal;
  justify-content: center;
}

.tr {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
}

.td {
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  flex-basis: 0;
  padding: 0.5em;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  white-space: nowrap;
  border-bottom: 1px solid #fff;
}
